import React from 'react';
import Nosotros from '../../componentes/Nosotros';
import './estilos.css';


function NosotrosPage() {
  return (
    <div className='cont-principal-nosotros'>
      <Nosotros />
    </div>
  )
}

export default NosotrosPage;