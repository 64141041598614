import React from 'react';
import Login from '../../componentes/Login';
import './estilos.css';

function LoginPage() {
    
    return (
        <div className='cont-login-page'>
            <Login />
        </div>
    )
}

export default LoginPage